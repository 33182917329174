import React from "react";
import "./home.css";
import smProfile from "./sm-profile.jpeg";
import lgProfile from "./bg-profile.jpeg";
import QRvCard from "./ChadvCard.png";
import Com from "./com";


const New = () => {
  return (
    <div className="App">
      <header className="App-header flex justify-center items-center h-full w-full">
        <div className="container m-8 w-fit">
          <div id="Tab1" className="flex place-content-end w-fit">
            <div id="Col1" className="flex-auto w-fit">
              <div className="h-20"></div>
              <div className="block md:hidden rounded-t-xl max-w-72 h-32 border-t-4 border-l-4 border-r-4 border-emerald-700 text-center bg-white opacity-70">
                <center>
                  <img
                    src={smProfile}
                    width="150"
                    valign="top"
                    alt="Small Profile"
                    className="rounded-full -mt-10 object-center"
                  />
                </center>
              </div>
              <div className=" block rounded-b-xl md:rounded-l-xl md:rounded-r-none border-r-4 md:border-r-0 md:border-t-4 border-l-4 border-b-4 border-emerald-700 sm:pt-0 md:pt-4 max-w-72 h-96 bg-white opacity-70">
                <p className="font-bold text-2xl text-center align-top">
                  Chad Wagner
                </p>
                <p className="text-xs text-center text-nowrap px-1">
                  Technologist, Brewer and Philanthropist
                </p>
                <div className="">
                  <img
                    src={QRvCard}
                    alt="QR Code vCard"
                    className="object-center pl-4 pr-4 md:pr-0"
                  />
                </div>
                <p className="text-center">
                  <a href="https://www.linkedin.com/in/chadanthonywagner/">
                    LinkedIn
                  </a>
                  |
                  <a href="https://www.upwork.com/freelancers/chadanthonywagner">
                    Upwork
                  </a>
                  <Com />
                </p>
              </div>
            </div>
            <div id="Col2" className="flex-auto w-fit pr-0 md:pr-4">
              <div className="h-20"></div>
              <div className="hidden md:block border-t-4 boarder-r-4 border-b-4 border-emerald-700 rounded-r-xl p-4 min-w-72 max-w-md h-96 place-content-center bg-white opacity-70">
                <div className="rounded-r-xl h-fit w-fit">
                  <img
                    src={lgProfile}
                    width="260"
                    alt="Large Profile"
                    className="rounded-r-xl object-center contrast-150 brightness-105"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default New;
