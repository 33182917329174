import {BrowserRouter as Router, Routes, Route,} from "react-router-dom";
import Home from "./home";
import Old from "./old";
import New from "./new";

function App() {
  return (
<Router>
  <Routes>
    <Route exact path="/" element={<Home />} />
    <Route exact path="/old" element={<Old />} />
    <Route exact path="/new" element={<New />} />
  </Routes>
</Router>
  );
}

export default App;
