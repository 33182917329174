import React from 'react'
import "./home.css";


const Old = () => {
  return (
    <div className="App">
      <header className="App-header">
        <div className="font-sans antialiased text-bg-gray-400/100 leading-normal tracking-wider">
          <div className="max-w-4xl flex place-items-center h-auto lg:h-screen flex-wrap mx-auto my-32 lg:my-0">
            <div
              id="profile"
              className="w-full lg:w-3/5 rounded-lg lg:rounded-l-lg lg:rounded-r-none shadow-2xl bg-white opacity-75 mx-6 lg:mx-0"
            >
              <div className="p-4 md:p-12 text-center lg:text-left">
                <div className="block lg:hidden rounded-full shadow-xl mx-auto -mt-16 h-48 w-48 bg-cover bg-center sm-profile"></div>
                <h1 className="text-3xl font-bold pt-8 lg:pt-0">Chad Wagner</h1>
                <div className="mx-auto lg:mx-0 w-4/5 pt-3 border-b-2 border-green-500 opacity-25"></div>
                <p className="pt-4 text-sm md:text-base font-bold items-center justify-center lg:text-nowrap">
                  Technologist, Brewer, Entrepreneur and Philanthropist
                </p>
                <img
                  src="https://chadwagner.com/ChadvCard.png"
                  alt="Contact QR Code"
                  className="content-center"
                />
                <div className="mt-6 pb-16 lg:pb-0 w-4/5 lg:w-full mx-auto flex flex-wrap items-center justify-between">
                  <a
                    className="link"
                    href="https://www.linkedin.com/in/chadanthonywagner/"
                    data-tippy-content="linkedin.com/in/chadanthonywagner/"
                  >
                    <svg
                      className="h-6 fill-current text-gray-600 hover:text-green-700"
                      role="img"
                      viewBox="0 0 48 48"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <title>LinkedIn</title>
                      <path d="M20.9716667,33.5527338 L25.001,33.5527338 L25.001,27.1328007 C25.001,25.439485 25.3213333,23.7988354 27.4206667,23.7988354 C29.491,23.7988354 29.517,25.7351486 29.517,27.2404662 L29.517,33.5527338 L33.5506667,33.5527338 L33.5506667,26.4341413 C33.5506667,22.9381777 32.796,20.2505391 28.711,20.2505391 C26.7483333,20.2505391 25.432,21.3265278 24.8943333,22.3471839 L24.839,22.3471839 L24.839,20.5725357 L20.9716667,20.5725357 L20.9716667,33.5527338 Z M16.423,14.1202696 C15.1273333,14.1202696 14.0823333,15.1682587 14.0823333,16.4595785 C14.0823333,17.7508984 15.1273333,18.7992208 16.423,18.7992208 C17.7133333,18.7992208 18.761,17.7508984 18.761,16.4595785 C18.761,15.1682587 17.7133333,14.1202696 16.423,14.1202696 L16.423,14.1202696 Z M14.4026667,33.5527338 L18.4406667,33.5527338 L18.4406667,20.5725357 L14.4026667,20.5725357 L14.4026667,33.5527338 Z M9.76633333,40 C8.79033333,40 8,39.2090082 8,38.2336851 L8,9.76631493 C8,8.79065843 8.79033333,8 9.76633333,8 L38.234,8 C39.2093333,8 40,8.79065843 40,9.76631493 L40,38.2336851 C40,39.2090082 39.2093333,40 38.234,40 L9.76633333,40 Z" />
                    </svg>
                  </a>
                  <a
                    className="link"
                    href="https://www.upwork.com/freelancers/chadanthonywagner"
                    data-tippy-content="upwork.com/freelancers/chadanthonywagner"
                  >
                    <svg
                      className="h-6 fill-current text-gray-600 hover:text-green-700"
                      role="img"
                      viewBox="0 0 48 48"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <title>Upwork</title>
                      <path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zM270.8 274.3c5.2 8.4 23.6 29.9 51.5 29.9v0c25.2 0 44.9-20.2 44.9-49.7s-19.4-49.7-44.9-49.7s-44.9 16.7-51.5 69.5zm-26.7-41.8c7.3-30.5 32.7-60.1 78.2-60.1l0 0c45.1 0 80.9 35.2 80.9 82.2s-35.9 81.9-80.9 81.9c-.6 0-1.1 0-1.7 0c-.5 0-1.1 0-1.6 0h-.1c-14.5-.5-28.7-4.8-40.9-12.6c-4.7-2.8-9.1-6-13.4-9.5l-12.8 78.4H214.9l19.4-110.6c-20.8-29.1-31.6-62.4-36.2-79.7V255c0 48-30.5 81.5-74.2 81.5c-22 0-41-8-54.8-23.3c-13.4-14.8-20.8-35.5-20.8-58.3V176.8H84.5l-.3 78.2c0 28.4 14.5 49.3 39.8 49.3s38.2-20.9 38.2-49.3V176.8h62.8c4.8 19.3 10.9 40.1 19.2 55.6z" />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
            <div className="w-full lg:w-2/5">
              <img
                src="https://chadwagner.com/bg-profile.jpeg"
                alt="Chad Wagner"
                className="rounded-none lg:rounded-lg shadow-2xl hidden lg:block"
              />
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default Old